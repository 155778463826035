import * as yup from 'yup';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Divider, Grid } from '@mui/material';
import CreateProduct from './components/createProduct';
import { FormProvider, useForm } from 'react-hook-form';
import ProductList from './components/orderProductList';
import InputField from 'src/shared-components/InputField';
import SelectCustom from 'src/shared-components/SelectCustom';
import { useEffect, useState, useMemo, useCallback } from 'react';
import FormControlCustom from 'src/shared-components/FormControlCustom';

import './order.scss';
import AddCustomer from './components/addCustomer';
import { columnsProduct } from 'src/utils/app-data';
import { useToast } from 'src/shared-components/Toast';
import useDebounce from 'src/shared-components/debounce';
import { getProduct } from 'src/services/stock.services';
import { useLocation, useNavigate } from 'react-router-dom';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AutocompleteCustom from 'src/shared-components/AutocompleteCustom';
import { createOrder, getOrderCustomer } from 'src/services/order.servies';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { currencyFormat, currencyMark, numberFormat } from 'src/utils/utils';
import { getBank, getCity, getDistricts, getLoadAllItem } from 'src/services/app.services';
import { LoadingButton } from '@mui/lab';
// import { groups } from 'src/utils/groups';

const Order = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const paramType = useLocation();

  const schema = yup.object().shape({
    customer: yup.object().typeError('Vui lòng chọn khách hàng').required('Vui lòng chọn khách hàng'),
    phone: yup.string().required('Vui lòng nhập số điện thoại'),
    address: yup.string().required('Vui lòng nhập địa chỉ'),
    email: yup.string(),
    district: yup.object().typeError('Vui lòng chọn quận huyện').required('Vui lòng chọn quận huyện'),
    province: yup.object().typeError('Vui lòng chọn quần huyện').required('Vui lòng chọn tỉnh thành phố'),
    placePickOrder: yup.object().typeError('Vui lòng chọn nơi chốt đơn').required('Vui lòng chọn nơi chốt đơn'),
    paymentPrice: yup
      .string()
      .test('isLarger', 'Vượt quá số tiền cần thanh toán', function (value: any, testContext: any) {
        return numberFormat(value) <= numberFormat(testContext?.parent?.price);
      })
      .test('price', 'Vui lòng nhập số tiền', function (value: any, testContext: any) {
        if (!isEmpty(value)) {
          return true;
        } else if (!isEmpty(testContext?.parent?.paymentType)) {
          return false;
        }
        return true;
      }),
    // group: yup.object().typeError('Vui lòng chọn loại khách hàng').required('Vui lòng chọn loại khách hàng'),
  });

  const { data: product, refetch } = useQuery(['product'], (params: any) => getProduct({ page: 1, pageSize: 10 }));
  const { data }: any = useQuery({
    queryKey: ['createProduct'],
  });

  const [address, setAddress] = useState<any>({
    province: null,
    district: null,
  });
  const [dataCustomer, setDataCustomer] = useState<any>();
  const [showDrawer, setShowDrawer] = useState(false);
  const [searchValue, setSearchValue] = useState<any>();
  const [productList, setProductList] = useState<any>([]);
  const [itemProduct, setItemProduct] = useState<any>([]);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [customerList, setCustomerList] = useState<any>([]);
  const [typeOrder, setTypeOrder] = useState<string>('false');
  const [filterParams, setFilterParams] = useState<any>(null);
  const [showAddCustomer, setShowAddCustomer] = useState<boolean>(false);
  const debouncedSearch = useDebounce(searchValue, 500);
  const [changeNote, setChangeNote] = useState<any>('');

  const { data: orderCustomerList } = useQuery(['customerList', { ...filterParams }], (params: any) =>
    getOrderCustomer({ ...filterParams }),
  );
  const { data: cityData } = useQuery(['city'], () => getCity({}));
  const { data: detailDistrict } = useQuery(
    ['district', address?.province?.id],
    () => getDistricts(address?.province?.id),
    {
      enabled: !!address?.province?.id,
    },
  );

  const { data: bankData } = useQuery(['bank'], () => getBank({}));

  const dateNow = moment(new Date()).format('DD-MM-YYYY hh:mm');
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  const defaultValues: any = useMemo(
    () => ({
      province: dataCustomer?.citiesId || '',
      email: dataCustomer?.email || '',
      address: dataCustomer?.address || '',
      phone: dataCustomer?.phone || '',
      district: dataCustomer?.districtId || '',
      customer: dataCustomer || '',
      note: '',
      price: '',
      createdDate: dateNow,
      placePickOrder: '',
      userReview: '',
      paymentTime: dateNow,
      paymentPrice: '',
      paymentNote: '',
      paymentType: '',
      paymentBank: '',
      resourceBank: '',
    }),
    [dataCustomer],
  );

  const methods = useForm({
    mode: 'onSubmit',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { formState, watch, setValue, handleSubmit, reset, getValues } = methods;
  const { errors } = formState;

  const customerWatch: any = watch('customer');

  const { data: productAll } = useQuery(['productAll', { itemProduct }, customerWatch?.id], () =>
    getLoadAllItem({ listItems: itemProduct, customerId: customerWatch?.id }),
  );

  const cityWatch: any = watch('province');
  const paymentPriceWatch = watch('paymentPrice');

  useEffect(() => {
    setValue('paymentPrice', currencyMark(paymentPriceWatch));
    if (!isEmpty(paymentPriceWatch)) {
      setValue('paymentType', bankData?.data?.TranferType[1]);
    } else {
      setValue('paymentType', '');
    }
  }, [paymentPriceWatch]);

  useEffect(() => {
    reset({ ...defaultValues });
  }, [dataCustomer]);

  useEffect(() => {
    setValue('email', customerWatch?.email);
    setValue('address', customerWatch?.address);
    setValue('phone', customerWatch?.phone);
    setValue('province', customerWatch?.cities);
    setValue('district', customerWatch?.district);
    setAddress({ ...address, province: customerWatch?.cities });
    setAddress({ ...address, district: customerWatch?.district });
    if (!isEmpty(customerWatch)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [customerWatch]);

  useEffect(() => {
    let itemProd: any = [];
    let totalPrice = 0;
    productList?.map((item: any, i: number) => {
      itemProd?.push({ productId: item?.productId ?? item?.id, quantity: Number(item?.quantity), note: item.note });
      totalPrice += productList[i].price * productList[i].quantity;
    });
    setValue('price', currencyFormat(totalPrice, ''));
    setItemProduct(itemProd);
  }, [productList]);

  useEffect(() => {
    const city: any = cityWatch;
    if (city) {
      setAddress({ ...address, province: city });
    }
  }, [cityWatch]);

  useEffect(() => {
    let afterGet = [...productList];
    productList.map((idParent: any, indexP: number) => {
      productAll?.data?.map((idChild: any) => {
        if (idParent.productId === idChild.productId) {
          productList[indexP]['price'] = idChild.price;
        }
      });
    });
    setProductList(afterGet);
  }, [productAll]);

  useEffect(() => {
    // Check them
    if (data) {
      const checkIsExits = productList?.find((item: any) => item?.productId === data?.productId);
      if (isEmpty(checkIsExits)) {
        let a = [...productList];
        a.push(data);
        setProductList(a);
        queryClient.setQueryData(['createProduct'], null);
      } else {
        let a = [...productList];
        productList.map((item: any, index: number) => {
          if (item.productId === checkIsExits.productId) {
            productList[index]['quantity'] = item.quantity + data.quantity;
          }
        });
        setProductList(a);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!isEmpty(orderCustomerList?.data)) {
      let customer = [...orderCustomerList?.data];
      customer?.unshift({ id: 0, name: '', phone: '' });
      setCustomerList(customer);
    } else {
      let customer = [];
      customer?.unshift({ id: 0, name: '', phone: '' });
      setCustomerList(customer);
    }
  }, [orderCustomerList]);

  useEffect(() => {
    setFilterParams({ ...filterParams, key: debouncedSearch?.data });
  }, [debouncedSearch?.data]);

  const mutationCreateOrder = useMutation({
    mutationFn: createOrder,
    onSuccess: (data: any) => {
      toast('success', 'Thêm mới thành công');
      navigate(`/order/detail/${data?.data?.id}`);
      reset();
      refetch();
      setProductList([]);
      setItemProduct([]);
      setCustomerList([]);
      // window.location.reload();
    },
    onError: (error: any) => {
      if (error.response.status === 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message);
      }
    },
  });

  const onChangeCustomer = (data: any) => {
    setSearchValue({
      data,
    });
  };

  const onChangeNote = (text: string) => {
    setChangeNote(text);
  };

  const onPressUpdateNote = (id: any, index: number) => {
    let prodNewNote: any = [...productList];
    prodNewNote[index]['note'] = changeNote;
    setProductList(prodNewNote);
  };

  const customerForm = (dataCus: any) => {
    setDataCustomer(dataCus);
    setDisabled(false);
  };

  const onDeleteProduct = (idx: number, index: number) => {
    let tmpRow: any;
    let item: any;

    // remove prod
    tmpRow = productList?.filter((itemId: any, index1: number) => index1 !== index);

    // remove item prod
    item = itemProduct?.filter((itemId: any, index1: number) => index1 !== index);
    setProductList(tmpRow);
    setItemProduct(item);
  };

  const onSubmit = (values: any) => {
    if (!isEmpty(productList)) {
      const params: any = {
        customerId: customerWatch?.id || dataCustomer?.id,
        name: values?.customer?.name || dataCustomer?.name,
        user: user.userName,
        phone: values?.phone,
        email: values?.email,
        citiesId: customerWatch?.cities?.id || dataCustomer?.citiesId || values?.province?.id,
        address: values?.address,
        districtId: customerWatch?.district?.id || dataCustomer?.districtId || values?.district?.id,
        placePickOrder: values?.placePickOrder?.name,
        items: itemProduct,
        userReview: '',
        note: values?.note,
        approve: typeOrder === 'approve',
        payment: values?.paymentType?.type
          ? {
              paymentType: values?.paymentType?.type,
              paymentBank: values?.paymentBank?.id,
              price: numberFormat(values.paymentPrice),
              note: values?.paymentNote,
              resourceBank: undefined,
            }
          : undefined,
        // groupId: values?.group.id,
      };

      mutationCreateOrder.mutate(params);
    } else {
      toast('warning', 'Bạn chưa chọn sản phẩm');
    }
  };

  const a = useCallback(
    (option: any, props: any, state: any) => {
      const checkBackground = () => {
        if (state?.index % 2 !== 0) {
          return { background: '#f5f7fa' };
        } else {
          return { background: 'white' };
        }
      };
      return (
        <>
          {option.id === 0 && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                className="btn-tertiary-disable"
                style={{ width: '100%' }}
                onClick={() => setShowAddCustomer(true)}
              >
                <AddOutlinedIcon style={{ fontSize: '16px' }} />
                <span>Thêm khách hàng </span>
              </Button>
            </div>
          )}
          {option.id === 0 ? (
            <></>
          ) : (
            <div
              style={
                option.id === 0
                  ? {}
                  : { paddingTop: '3px', paddingBottom: '3px', backgroundColor: checkBackground().background }
              }
            >
              <div style={{ padding: '3px', display: 'flex', justifyContent: 'center' }} {...props}>
                <div style={{ width: '200px', display: 'flex', justifyContent: 'center' }}>
                  <span> {option.name}</span>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <span>{option.phone}</span>
                </div>
              </div>
            </div>
          )}
        </>
      );
    },
    [undefined],
  );

  const checkTrackingOrder = () => {
    if (paramType.state === 'detail') {
      return (
        <div className={'page-layout'}>
          <Grid className={'align-items-center header_title'}>
            <Grid md={7}>
              <h2 className={'txt-title'}>Vận chuyển</h2>
            </Grid>
            <div className="item-btn-right">
              <Button className={'btn-create'}>
                <span className={'txt'}>Hủy ship</span>
              </Button>
              <Button className={'btn-create'}>
                <span className={'txt'}>Thêm ship</span>
              </Button>
            </div>
          </Grid>
          <Divider />
        </div>
      );
    }
  };

  const checkInfoOrder = () => {
    return (
      <div className={'page-layout'} style={{ marginLeft: '0px' }}>
        <div className={'align-items-center header_title'}>
          <h2 className={'txt-title'}>CHI TIẾT ĐƠN HÀNG</h2>
        </div>
        <Divider />
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="content mt-2">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={12}>
                  <FormControlCustom label={'Ngày tạo đơn'} fullWidth>
                    <InputField
                      style={{ width: '100%' }}
                      name={'createdDate'}
                      placeholder={'Nhập ngày tạo đơn'}
                      error={Boolean(errors.createdDate)}
                      helperText={''}
                      disabled={true}
                      className={'disabled-field'}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={12} sm={6} md={12}>
                  <FormControlCustom label={'Thành tiền'} fullWidth>
                    <InputField
                      style={{ width: '100%' }}
                      name={'price'}
                      placeholder={'Nhập thành tiền'}
                      error={Boolean(errors.price)}
                      helperText={''}
                      disabled={true}
                      className={'disabled-field'}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={12}>
                  <FormControlCustom label={'Ghi chú'} fullWidth>
                    <InputField
                      style={{ width: '100%' }}
                      name={'note'}
                      placeholder={'Nhập ghi chú'}
                      error={Boolean(errors.note)}
                      helperText={''}
                      rows={3}
                      multiline
                    />
                  </FormControlCustom>
                </Grid>
              </Grid>
            </div>
            <Grid container spacing={2} className={`mt-1`} justifyContent="center">
              <Grid item xs={6} md={5}>
                <LoadingButton
                  loading={mutationCreateOrder.isLoading}
                  loadingPosition="start"
                  variant="contained"
                  size="medium"
                  className={`btn-tertiary-normal`}
                  style={{ height: '2rem', whiteSpace: 'nowrap' }}
                  type="submit"
                  onClick={() => setTypeOrder('')}
                >
                  Tạo đơn
                </LoadingButton>
              </Grid>
              <Grid item xs={6} md={7}>
                <LoadingButton
                  loading={mutationCreateOrder.isLoading}
                  loadingPosition="start"
                  variant="contained"
                  size="medium"
                  className={`btn-tertiary-normal`}
                  style={{ height: '2rem', whiteSpace: 'nowrap' }}
                  type="submit"
                  onClick={() => setTypeOrder('approve')}
                >
                  Tạo và duyệt đơn
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        <Divider className={`mt-3`} />
      </div>
    );
  };

  const checkPaymentOrder = () => {
    return (
      <Grid md={12}>
        <div className={'page-layout'} style={{ marginLeft: '0px' }}>
          <Grid className={'align-items-center header_title'}>
            <Grid md={7}>
              <h2 className={'txt-title'}>THANH TOÁN</h2>
            </Grid>
          </Grid>
          <Divider />
          <FormProvider {...methods}>
            <form>
              <div className="content mt-2">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={12}>
                    <FormControlCustom label={'Thời gian'} fullWidth>
                      <InputField
                        style={{ width: '100%' }}
                        name={'paymentTime'}
                        placeholder={'Nhập thời gian'}
                        error={Boolean(errors.createdDate)}
                        helperText={''}
                        disabled={true}
                        className={'disabled-field'}
                      />
                    </FormControlCustom>
                  </Grid>

                  <Grid item xs={12} sm={6} md={12}>
                    <FormControlCustom label={'Số tiền'} fullWidth>
                      <InputField
                        style={{ width: '100%' }}
                        name={'paymentPrice'}
                        placeholder={'Nhập thành tiền'}
                        error={Boolean(errors.paymentPrice)}
                        helperText={errors?.paymentPrice?.message as string}
                      />
                    </FormControlCustom>
                  </Grid>

                  <Grid item xs={12} sm={6} md={12}>
                    <FormControlCustom label={'Ngân hàng'} fullWidth>
                      <SelectCustom
                        style={{ width: '100%' }}
                        name={'paymentBank'}
                        placeholder={'Chọn bank'}
                        options={bankData?.data?.Bank || []}
                      />
                    </FormControlCustom>
                  </Grid>

                  <Grid item xs={12} sm={6} md={12}>
                    <FormControlCustom label={'PTTT'} fullWidth>
                      <SelectCustom
                        style={{ width: '100%' }}
                        name={'paymentType'}
                        placeholder={'Chọn PTTT'}
                        options={bankData?.data?.TranferType || []}
                        optionLabelKey={'name'}
                        disableClear={true}
                      />
                    </FormControlCustom>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlCustom
                      classNameLabel={
                        'flex justify-content-center align-items-center mr-1 w-100 justify-content-start order-custom-title'
                      }
                      className={'flex-direction-row'}
                      label={'Ghi chú'}
                      fullWidth
                    >
                      <InputField
                        style={{ width: '100%' }}
                        multiline
                        rows={3}
                        name={'paymentNote'}
                        placeholder={'Nhập ghi chú'}
                        error={Boolean(errors.note)}
                        helperText={''}
                      />
                    </FormControlCustom>
                  </Grid>
                </Grid>
              </div>
            </form>
          </FormProvider>
        </div>
      </Grid>
    );
  };

  return (
    <div className={'page-layout-blank'}>
      <Grid container>
        <Grid md={8} xs={12}>
          <div className={'page-layout'}>
            <Grid className={'align-items-center header_title'}>
              <Grid md={7}>
                <h2 className={'txt-title'}>THÔNG TIN KHÁCH HÀNG</h2>
              </Grid>
            </Grid>
            <Divider />
            <FormProvider {...methods}>
              <form>
                <div className="content mt-2">
                  <Grid container spacing={1}>
                    <Grid item xs={12} className={'media'} sm={6}>
                      <FormControlCustom label={'Khách hàng*'} fullWidth>
                        <div className="view-input">
                          <AutocompleteCustom
                            style={{ width: '100%' }}
                            name={'customer'}
                            placeholder={'Chọn khách hàng'}
                            error={Boolean(errors?.customer)}
                            options={customerList || []}
                            renderOption={a}
                            onChangeValue={onChangeCustomer}
                            optionLabelKey={'name'}
                          />
                        </div>
                      </FormControlCustom>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControlCustom label={'Địa chỉ'} fullWidth>
                        <div className="view-input">
                          <InputField
                            className={disabled ? 'disabled-field' : ''}
                            style={{ width: '100%' }}
                            name={'address'}
                            placeholder={''}
                            error={Boolean(errors?.address)}
                            disabled={disabled}
                          />
                        </div>
                      </FormControlCustom>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControlCustom label={'Điện thoại'} fullWidth>
                        <div className="view-input">
                          <InputField
                            className={disabled ? 'disabled-field' : ''}
                            style={{ width: '100%' }}
                            name={'phone'}
                            helperText={''}
                            placeholder={''}
                            disabled={disabled}
                            error={Boolean(errors?.phone)}
                          />
                        </div>
                      </FormControlCustom>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControlCustom label={'Tỉnh thành'} fullWidth>
                        <SelectCustom
                          className={disabled ? 'disabled-field' : ''}
                          style={{ width: '100%' }}
                          name={'province'}
                          placeholder={''}
                          options={cityData?.data || []}
                          optionLabelKey={'name'}
                          disabled={disabled}
                          error={Boolean(errors?.province)}
                        />
                      </FormControlCustom>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControlCustom label={'Email'} fullWidth>
                        <InputField
                          className={disabled ? 'disabled-field' : ''}
                          style={{ width: '100%' }}
                          name={'email'}
                          helperText={''}
                          placeholder={''}
                          disabled={disabled}
                          error={Boolean(errors?.email)}
                        />
                      </FormControlCustom>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControlCustom label={'Quận huyện'} fullWidth>
                        <SelectCustom
                          className={disabled ? 'disabled-field' : ''}
                          style={{ width: '100%' }}
                          name={'district'}
                          placeholder={''}
                          options={detailDistrict?.data || []}
                          optionLabelKey={'name'}
                          disabled={disabled}
                          error={Boolean(errors?.district)}
                        />
                      </FormControlCustom>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControlCustom label={'Nơi chốt đơn'} fullWidth>
                        <SelectCustom
                          style={{ width: '100%' }}
                          name={'placePickOrder'}
                          placeholder={'Chọn nơi chốt đơn'}
                          options={bankData?.data?.orderPlaces}
                          error={Boolean(errors?.placePickOrder)}
                          optionLabelKey={'name'}
                        />
                      </FormControlCustom>
                    </Grid>
                  </Grid>
                </div>
              </form>
            </FormProvider>
          </div>
          <div className={'page-layout'}>
            <Grid className={'align-items-center header_title'}>
              <Grid md={7}>
                <h2 className={'txt-title'}>SẢN PHẨM</h2>
              </Grid>
              <div className="item-btn-right">
                <Button onClick={() => setShowDrawer(true)} className={'btn-create'}>
                  <span className={'txt'}>Thêm sản phẩm</span>
                </Button>
              </div>
            </Grid>
            <div className="container-table">
              <ProductList
                rows={productList}
                columns={columnsProduct}
                deleteProduct={onDeleteProduct}
                type="order"
                textNote={onChangeNote}
                // onChangeNote={note}
                onPressNote={onPressUpdateNote}
              />
            </div>
          </div>

          {checkTrackingOrder()}
        </Grid>
        <Grid md={4} xs={12}>
          {checkInfoOrder()}
          {/* payment */}
          {checkPaymentOrder()}
        </Grid>
      </Grid>

      <div>
        <CreateProduct
          setShowDrawer={setShowDrawer}
          customerId={customerWatch?.id || dataCustomer?.id}
          showDrawer={showDrawer}
          listItem={productList}
        />
        <AddCustomer
          setShowDrawer={setShowAddCustomer}
          showDrawer={showAddCustomer}
          customerCreateForm={customerForm}
        />
      </div>
    </div>
  );
};

export default Order;
