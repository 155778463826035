const columns = [
  {
    id: 1,
    field: 'stt',
    headerName: 'STT',
    width: '3rem',
    flex: 40,
    headerAlign: 'center',
    headerClassName: 'theme',
    sortable: false,
  },
  {
    id: 2,
    field: 'code',
    headerName: 'Mã SKU',
    width: '4rem',
    flex: 80,
    headerAlign: 'center',
    headerClassName: 'theme',
    sortable: false,
  },
  {
    id: 3,
    field: 'brand',
    headerName: 'Thương hiệu',
    width: '7rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    id: 4,
    field: 'category',
    headerName: 'Loại sản phẩm',
    width: '7rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    id: 5,
    field: 'image',
    headerName: 'Ảnh',
    width: '2.5rem',
    flex: 78,
    height: '1rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    id: 6,
    field: 'name',
    headerName: 'Tên sản phẩm',
    width: '5rem',
    headerAlign: 'center',
    headerClassName: 'theme',
    flex: 160,
  },
  {
    id: 7,
    field: 'status',
    headerName: 'Tình trạng',
    width: '6rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    id: 8,
    field: 'amount',
    headerName: 'SL',
    width: '2.5rem',
    flex: 70,
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    id: 9,
    field: 'payed',
    headerName: 'Đã bán',
    width: '2.5rem',
    flex: 70,
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    id: 10,
    field: 'totalPrice',
    headerName: 'Tổng giá trị',
    width: '9rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    id: 11,

    field: 'note',
    headerName: 'Ghi chú',
    width: '15rem',
    flex: 200,
    editable: true,
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    id: 12,
    width: '1rem',
    flex: 10,
  },
];

const columnsNoPermission = [
  {
    id: 1,
    field: 'stt',
    headerName: 'STT',
    width: '3rem',
    flex: 40,
    headerAlign: 'center',
    headerClassName: 'theme',
    sortable: false,
  },
  {
    id: 2,
    field: 'code',
    headerName: 'Mã SKU',
    width: '4rem',
    flex: 80,
    headerAlign: 'center',
    headerClassName: 'theme',
    sortable: false,
  },
  {
    id: 3,
    field: 'brand',
    headerName: 'Thương hiệu',
    width: '7rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    id: 4,
    field: 'category',
    headerName: 'Loại sản phẩm',
    width: '7rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    id: 5,
    field: 'image',
    headerName: 'Ảnh',
    width: '2.5rem',
    flex: 78,
    height: '1rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    id: 6,
    field: 'name',
    headerName: 'Tên sản phẩm',
    width: '5rem',
    headerAlign: 'center',
    headerClassName: 'theme',
    flex: 160,
  },
  {
    id: 7,
    field: 'status',
    headerName: 'Tình trạng',
    width: '6rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    id: 8,
    field: 'amount',
    headerName: 'SL',
    width: '2.5rem',
    flex: 70,
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    id: 9,
    field: 'payed',
    headerName: 'Đã bán',
    width: '2.5rem',
    flex: 70,
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    id: 10,

    field: 'note',
    headerName: 'Ghi chú',
    width: '15rem',
    flex: 200,
    editable: true,
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    id: 11,
    width: '1rem',
    flex: 10,
  },
];

const status = [
  {
    codeName: 'ALL',
    name: 'Tất cả',
  },
  {
    codeName: 'OUTSTOCK',
    name: 'Hết hàng',
  },
  {
    codeName: 'STOCK',
    name: 'Còn hàng',
  },
];

const inPreview = [
  {
    preview: '',
    name: 'Tất cả',
  },
  {
    preview: false,
    name: 'Bán hàng',
  },
  {
    preview: true,
    name: 'Không Bán hàng',
  },
];

const timeInventory = [
  {
    codeName: 'all',
    name: 'Tất cả',
  },
  {
    codeName: 3,
    name: '3 tháng',
  },
  {
    codeName: 6,
    name: '6 tháng',
  },
  {
    codeName: 12,
    name: '12 tháng',
  },
];

const brand = [
  {
    codeName: 'tommy',
    name: 'Tommy',
  },
  {
    codeName: 'gucci',
    name: 'Gucci',
  },
  {
    codeName: 'LV',
    name: 'Louis Vuitton',
  },
];

const supplier = [
  {
    codeName: 'VN',
    name: 'Việt Nam',
  },
  {
    codeName: 'US',
    name: 'Mỹ',
  },
  {
    codeName: 'UK',
    name: 'Anh Quốc',
  },
];

const category = [
  {
    codeName: 'cosmetics',
    name: 'Mỹ phẩm',
  },
  {
    codeName: 'clothes',
    name: 'Áo quần',
  },
];

const branch = [
  {
    codeName: 'all',
    name: 'Tất cả',
  },
  {
    codeName: 'HOCHIMINH',
    name: 'Hồ Chí Minh',
  },
  {
    codeName: 'HANOI',
    name: 'Hà Nội',
  },
];

const branchCreate = [
  {
    codeName: 'HOCHIMINH',
    name: 'Hồ Chí Minh',
  },
  {
    codeName: 'HANOI',
    name: 'Hà Nội',
  },
  {
    codeName: 'SPUPLIER',
    name: 'Nhà cung cấp',
  },
];

const branchData = [
  {
    codeName: 'HOCHIMINH',
    name: 'Hồ Chí Minh',
  },
  {
    codeName: 'HANOI',
    name: 'Hà Nội',
  },
];

const sku = [
  {
    codeName: 'sku1',
    name: 'SKU 001',
    productName: 'Áo Tom',
  },
  {
    codeName: 'sku2',
    name: 'SKU 002',
    productName: 'Quần Jean',
  },
  {
    codeName: 'sku3',
    name: 'SKU 003',
    productName: 'Iphone 11',
  },
  {
    codeName: 'sku4',
    name: 'SKU 004',
    productName: 'Áo ',
  },
];

const rowChild = [
  {
    stt: 1,
    code: 'Sku-1',
    brand: 'd',
    image: 'gi do',
    nature: 'Màu đen',
    amount: '10',
    discount: '10%',
    priceEnter: '1.000.000d',
    sold: 10,
    originalPrice: '1.000.000',
    finalPrice: '1.200.000',
    inspectionDate: '22/2/2022',
    input: '22/2/2022',
    note: 'note khi tạo',
  },
  {
    stt: 2,
    code: 'Sku-2',
    brand: 'd',
    image: 'gi do',
    nature: 'Màu đỏ',
    amount: '10',
    discount: '10%',
    priceEnter: '1.000.000d',
    sold: 10,
    originalPrice: '1.000.000',
    finalPrice: '1.200.000',
    inspectionDate: '22/2/2022',
    input: '22/2/2022',
    note: 'note khi tạo',
  },
];

const brandList = [
  {
    id: 1,
    stt: 1,
    code: 'LV',
    name: 'ELVY',
  },
  {
    id: 2,
    stt: 2,
    code: 'NIKE',
    name: 'Nike',
  },
];

const headerChild = [
  {
    id: 1,
    name: 'Mã SP',
    width: '4rem',
    flex: 170,
  },
  {
    id: 2,
    name: 'Chi nhánh',
    flex: 110,
  },
  {
    id: 3,
    name: 'Ảnh',
    width: '3rem',
    flex: 78,
  },
  {
    id: 4,
    name: 'Đặc tính',
    flex: 90,
  },
  {
    id: 5,
    name: 'SL',
    width: '3rem',
    flex: 50,
  },
  {
    id: 6,
    name: 'Giảm giá(%)',
    width: '3.5rem',
    flex: 80,
  },
  // {
  //   id: 7,
  //   name: 'Giá nhập',
  //   flex: 100,
  // },
  {
    id: 8,
    name: 'Đã bán',
    flex: 50,
  },
  {
    id: 9,
    name: 'Giá bán gốc',
    flex: 100,
  },
  {
    id: 10,
    name: 'Giá bán cuối',
    flex: 100,
  },
  {
    id: 11,
    name: 'Ngày nhập kho',
    flex: 105,
  },
  {
    id: 12,
    name: 'Ngày kiểm',
    flex: 105,
  },
  {
    id: 13,
    name: 'Ghi chú',
    flex: 140,
  },
  // {
  //   id: 14,
  //   flex: 15,
  // },
];

const columnsProduct = [
  {
    field: 'stt',
    headerName: 'STT',
    width: '2rem',
    headerAlign: 'center',
    headerClassName: 'theme',
    sortable: false,
  },

  {
    field: 'images',
    headerName: 'Ảnh',
    width: '3rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    field: 'name',
    headerName: 'Tên sản phẩm',
    width: '6rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    field: 'variations',
    headerName: 'Thuộc tính',
    width: '6rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    field: 'quantity',
    headerName: 'SL',
    width: '2rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    field: 'price',
    headerName: 'Giá SP',
    width: '7rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    field: 'Ptotal',
    headerName: 'Tổng giá trị',
    width: '7rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    field: 'detail',
    width: '2rem',
  },
];

const columnsProductDetail = [
  {
    field: 'stt',
    headerName: 'STT',
    width: '2rem',

    headerAlign: 'center',
    headerClassName: 'theme',
    sortable: false,
  },

  {
    field: 'images',
    headerName: 'Ảnh',
    width: '5rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    field: 'name',
    headerName: 'Tên sản phẩm',
    width: '8rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    field: 'variations',
    headerName: 'Thuộc tính',
    width: '6rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    field: 'quantity',
    headerName: 'SL',
    width: '2rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    field: 'price',
    headerName: 'Giá SP',
    width: '5rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    field: 'Ptotal',
    headerName: 'Tổng giá trị',
    width: '5rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    width: '3rem',
  },
];

const gender = [
  {
    id: 1,
    code: 'male',
    name: 'Nam',
  },
  {
    id: 2,
    code: 'female',
    name: 'Nữ',
  },
  {
    id: 3,
    code: 'other',
    name: 'Khác',
  },
];

const subgroups = [
  {
    id: 3,
    name: 'Khách lẻ',
  },
  {
    id: 2,
    name: 'CTV',
  },
  {
    id: 1,
    name: 'Khách buôn',
  },
  {
    id: 4,
    name: 'Sỉ to',
  },
];

const orderStatus = [
  {
    id: 1,
    code: 'IN_REVIEW',
    name: 'Chờ duyệt',
  },
  {
    id: 2,
    code: 'IN_PROCESS',
    name: 'Đang GD',
  },
  {
    id: 3,
    code: 'DONE',
    name: 'Hoàn thành',
  },
  {
    id: 4,
    code: 'CANCEL',
    name: 'Đã hủy',
  },
];

interface inputGroup {
  id: number;
  code: number;
  name: string;
}

const orderGroup: inputGroup[] = [
  {
    id: 1,
    code: 1,
    name: 'Khách buôn',
  },
  {
    id: 2,
    code: 2,
    name: 'CTV',
  },
  {
    id: 3,
    code: 3,
    name: 'Khách lẻ',
  },
];

const columnProductTracking = [
  {
    field: 'name',
    headerName: 'Sản phẩm',
    width: '6rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    field: 'variants',
    headerName: 'Thuộc tính',
    width: '4rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    field: 'branch',
    headerName: 'Chi nhánh',
    width: '4rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    field: 'quantity',
    headerName: 'SL',
    width: '2rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
];

const columnTracking = [
  {
    field: 'code',
    headerName: 'Mã vận đơn',
    width: '15rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    field: 'createdDate',
    headerName: 'Ngày tạo',
    width: '6rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    field: 'dv',
    headerName: 'Đơn vị',
    width: '6rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    field: 'cod',
    headerName: 'Tiền thu COD',
    width: '6rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    field: 'status',
    headerName: 'Trạng thái',
    width: '6rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    field: 'feeShipper1',
    headerName: '',
    width: '3rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
];

const columnChildTracking = [
  {
    field: 'name',
    headerName: 'Sản phẩm',
    width: '7rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    field: 'amount',
    headerName: 'SL',
    width: '2rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    field: 'Variants',
    headerName: 'Thuộc tính',
    width: '5rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    field: 'price',
    headerName: 'Thành tiền',
    width: '5rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
];

const filterDateTime = [
  {
    id: 1,
    code: 'ALL',
    name: 'Tất cả',
  },
  {
    id: 2,
    code: 'week',
    name: 'Trong tuần',
  },
  {
    id: 3,
    code: 'month',
    name: 'Trong tháng',
  },
  {
    id: 4,
    code: 'option',
    name: 'Tùy chọn',
  },
];

const columnsOrder = [
  {
    id: 1,
    field: 'createdAt',
    headerName: 'Ngày tạo đơn',
    // width: '3rem',
    flex: 80,
    headerAlign: 'center',
    headerClassName: 'theme',
    sortable: false,
  },
  {
    id: 2,
    field: 'orderNumber',
    headerName: 'Mã ĐH',
    // width: '4rem',
    flex: 100,

    headerAlign: 'center',
    headerClassName: 'theme',
    sortable: false,
  },
  {
    id: 3,
    field: 'customer',
    headerName: 'Khách hàng',
    flex: 100,

    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    id: 4,
    field: 'status',
    headerName: 'Trạng thái',
    flex: 90,

    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    id: 5,
    field: 'total',
    headerName: 'Tổng tiền',
    width: '2.5rem',
    flex: 85,
    height: '1rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    id: 6,
    field: 'totalAmount',
    headerName: 'Thành tiền',
    width: '5rem',
    headerAlign: 'center',
    headerClassName: 'theme',
    flex: 85,
  },
  {
    id: 7,
    field: 'totalPaid',
    headerName: 'Khách đã trả',
    flex: 80,

    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    id: 8,
    field: 'pay',
    headerName: 'Còn lại',
    width: '2.5rem',
    flex: 70,
    headerAlign: 'center',
    headerClassName: 'theme',
  },

  {
    id: 11,
    field: 'note',
    headerName: 'Ghi chú',
    width: '15rem',
    flex: 110,
    editable: true,
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    id: 10,
    field: 'userCreate',
    headerName: 'NV tạo đơn',
    flex: 90,
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    field: 'userReivew',
    headerName: 'NV duyệt đơn',
    id: 13,
    width: '1rem',
    flex: 100,
  },
];

const headerChildOrder = [
  {
    id: 12,
    name: 'Hình ảnh',
    width: '4rem',
    flex: 75,
  },
  {
    id: 1,
    name: 'Sản phẩm',
    width: '4rem',
    flex: 200,
  },
  {
    id: 2,
    name: 'Thuộc tính',
    flex: 100,
  },
  {
    id: 3,
    name: 'SL',
    width: '3rem',
    flex: 78,
  },
  {
    id: 4,
    name: 'Giá',
    flex: 90,
  },
  {
    id: 5,
    name: 'Tình trạng',
    width: '3rem',
    flex: 50,
  },
  {
    id: 6,
    name: 'Giá vnđ',
    width: '3.5rem',
    flex: 80,
  },
  {
    id: 7,
    name: 'Ghi chú',
    flex: 100,
  },
];

const discountData = [
  {
    type: 'PERCENT',
    name: '%',
  },
  {
    type: 'AMOUNT',
    name: 'đ',
  },
];

const refundData = [
  {
    stt: 1,
    branch: 'HOCHIMINH',
    codeStock: 'MA',
    id: 'SKU439-130',
    nameProduct: 'Sản phẩm A',
    variations: [
      {
        name: 'Màu',
        value: 'Trắng',
      },
      {
        name: 'size',
        value: '40',
      },
    ],
    pricePay: 1400000,
    amount: 10,
    staff: 'Bảo Đạt',
    note: 'Bảo Đạt là QC, nhưng ko làm QC đường đời đưa đẩy làm qc',
  },
];

const columnRefundOrder = [
  {
    field: 'name',
    headerName: 'Sản phẩm',
    width: '4rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
  {
    field: 'variants',
    headerName: 'Thuộc tính',
    width: '5rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },

  {
    field: 'quantity',
    headerName: 'SL',
    width: '3rem',
    headerAlign: 'center',
    headerClassName: 'theme',
  },
];

export {
  columns,
  columnsNoPermission,
  brand,
  category,
  supplier,
  status,
  inPreview,
  timeInventory,
  branch,
  rowChild,
  brandList,
  sku,
  headerChild,
  branchCreate,
  columnsProduct,
  gender,
  subgroups,
  orderStatus,
  orderGroup,
  columnProductTracking,
  columnTracking,
  columnChildTracking,
  filterDateTime,
  columnsProductDetail,
  columnsOrder,
  headerChildOrder,
  discountData,
  branchData,
  refundData,
  columnRefundOrder,
};
