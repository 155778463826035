import { Button, Divider, Grid, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import fileDownload from 'js-file-download';
import { useNavigate } from 'react-router';
import { getBrand, getSupplier, getUsers } from 'src/services/app.services';
import { downloadExcel, getOrder } from 'src/services/order.servies';
import { checkWareHouse, updateDiscount, updateNoteSkuProduct } from 'src/services/stock.services';
import FormControlCustom from 'src/shared-components/FormControlCustom';
import ModalAlert from 'src/shared-components/Modal';
import SearchInput from 'src/shared-components/SearchInput/Index';
import SelectCustom from 'src/shared-components/SelectCustom';
import SnackBarCustom from 'src/shared-components/Snackbar';
import { useToast } from 'src/shared-components/Toast';
import useDebounce from 'src/shared-components/debounce';
import { filterDateTime, orderGroup, orderStatus } from 'src/utils/app-data';
import { currencyFormat } from 'src/utils/utils';
import CreateStock from './components/createStock';
import OrderExpand from './components/orderExpand';
import './orderList.scss';
import moment from 'moment';

const OderList = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const firstDay = new Date(currentYear, 0, 1);
  const lastDay = new Date(currentYear, 11, 31);

  const [showDrawer, setShowDrawer] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValueChange] = useState<any>(0);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [selectedIdChildRow, setSelectedIdChildRow] = useState<any>([]);
  const [textNote, setTextNote] = useState<any>('');
  const [perDiscount, setPerDiscount] = useState<any>(0);
  const [searchValue, setSearchValue] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(20);
  const [filterParams, setFilterParams] = useState<any>(null);
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [selectedDate, setSelectedDate] = useState<any>({
    dateFrom: firstDay,
    dateTo: lastDay,
  });
  const [disable, setDisable] = useState<boolean>(true);
  const debouncedSearch = useDebounce(searchValue, 500);

  const defaultValues = {
    brand: null,
    status: null,
    user: null,
    outOfDate: null,
    branch: null,
    time: null,
    groupId: null,
  };

  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit, reset, watch } = methods;

  const watchGroup: any = watch('groupId');
  const watchStatus: any = watch('status');
  const watchBrand: any = watch('brand');
  const watchUser: any = watch('user');
  const watchOutOfDate: any = watch('outOfDate');
  const watchBranch: any = watch('branch');
  const watchTime: any = watch('time');

  const { data: dataOrder, refetch } = useQuery(['order', { ...filterParams, page, pageSize }], (params: any) =>
    getOrder({ page: page + 1, pageSize: pageSize, ...filterParams }),
  );

  const { data: dataBrand } = useQuery(['dataBrand'], () => getBrand({}));
  const { data: dataSupplier } = useQuery(['dataSupplier'], () => getSupplier({}));
  const { data: dataUser } = useQuery(['users'], () => getUsers({}));

  const mutationUpdateNote = useMutation({
    mutationFn: updateNoteSkuProduct,
    onSuccess: (data: any) => {
      toast('success', 'Chỉnh sửa thành công');
      refetch();
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message);
      }
    },
    onSettled: () => {
      refetch();
    },
  });

  const mutationDiscount = useMutation({
    mutationFn: updateDiscount,
    onSuccess: (data: any) => {
      toast('success', 'Chỉnh sửa thành công');
      setOpenModal(false);
      refetch();
      setSelectedIdChildRow([]);
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message);
      }
    },
    onSettled: () => {
      refetch();
    },
  });

  const mutationCheckHouse = useMutation({
    mutationFn: checkWareHouse,
    onSuccess: (data: any) => {
      toast('success', 'Kiểm kho thành công');
      setOpenModal(false);
      refetch();
      setSelectedIdChildRow([]);
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message);
      }
    },
    onSettled: () => {
      refetch();
    },
  });

  const mutationSearchProduct = useMutation({
    mutationFn: getOrder,
    onSuccess: (data: any) => {
      queryClient.setQueryData(['order'], data);
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message);
      }
    },
  });

  useEffect(() => {
    setFilterParams({ ...filterParams, key: searchValue });
  }, [searchValue]);

  useEffect(() => {
    const params = {
      status: watchStatus?.code,
      outOfDate: watchOutOfDate?.codeName,
      brand: watchBrand,
      branch: watchBranch?.codeName,
      userReview: watchUser?.id,
      startDate: moment(selectedDate?.dateFrom).startOf('day').valueOf(),
      endDate: moment(selectedDate?.dateTo).endOf('day').valueOf(),
      key: debouncedSearch,
      time: watchTime?.code,
      user: watchUser?.name,
      groupId: watchGroup?.code,
    };
    setFilterParams(params);
  }, [
    watchStatus,
    watchOutOfDate,
    watchBrand,
    watchUser,
    watchBranch,
    watchTime,
    selectedDate?.dateFrom,
    selectedDate?.dateTo,
    watchGroup,
  ]);

  useEffect(() => {
    // setSelectedDate({ ...selectedDate, dateFrom: firstDay, dateTo: lastDay });
  }, [selectedDate?.dateFrom, selectedDate?.dateTo]);

  useEffect(() => {
    setFilterParams({ ...filterParams, key: debouncedSearch });
  }, [debouncedSearch]);

  useEffect(() => {
    const now = new Date();

    if (watchTime?.code === 'month') {
      const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
      const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

      setSelectedDate({ ...selectedDate, dateFrom: firstDay, dateTo: lastDay });
    } else if (watchTime?.code === 'week') {
      const firstDay = getFirstDayOfWeek(now);
      const lastDay = new Date(firstDay);
      lastDay.setDate(lastDay.getDate() + 6);

      setSelectedDate({ ...selectedDate, dateFrom: firstDay, dateTo: lastDay });
    } else if (watchTime?.code === 'option') {
      // setSelectedDate({ ...selectedDate, dateFrom: '', dateTo: '' });
    } else {
      const currentYear = new Date().getFullYear();
      const firstDay = new Date(currentYear, 0, 1);
      const lastDay = new Date(currentYear, 11, 31);

      setSelectedDate({ ...selectedDate, dateFrom: firstDay, dateTo: lastDay });
    }
  }, [watchTime]);

  useEffect(() => {
    if (watchTime?.code === 'option') {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [watchTime]);

  const downloadMutation = useMutation({
    mutationFn: downloadExcel,
  });

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    if (!isEmpty(selectedIdChildRow)) {
      setOpenModal(true);
    } else {
      toast('warning', 'Vui lòng chọn sản phẩm');
    }
  };

  const mutationExportExcel = useMutation({
    mutationFn: downloadExcel,
    onSuccess: (data: any) => {
      const fileName = 'DS_DON_HANG' + new Date().toISOString() + '.xlsx';
      fileDownload(data.data, fileName);
      toast('success', 'Tải xuống thành công');
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message);
      }
    },
  });

  const exportExcel = () => {
    mutationExportExcel.mutate({ ...filterParams });
  };

  const onConfirmDiscount = () => {
    const params = {
      ids: selectedIdChildRow,
      discountPercent: Number(perDiscount),
    };
    mutationDiscount.mutate(params);
  };

  const onCheckWareHouse = () => {
    const params = {
      ids: selectedIdChildRow,
    };
    if (!isEmpty(selectedIdChildRow)) {
      mutationCheckHouse.mutate(params);
    } else {
      toast('warning', 'Vui lòng chọn sản phẩm');
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValueChange(newValue);
  };

  const handleSelectRow = (row: any) => {
    setSelectedRow(row);
  };

  function getFirstDayOfWeek(d: any) {
    const date = new Date(d);
    const day = date.getDay(); // 👉️ get day of week

    const diff = date.getDate() - day + (day === 0 ? -6 : 1);

    return new Date(date.setDate(diff));
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value);
    setPage(0);
  };

  const handleChangeDiscount = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPerDiscount(event.target.value);
  };

  const onDetailOrder = (id: any) => {
    navigate(`/order/detail/${id}`);
  };

  const onEditNote = () => {
    const params = {
      skuProductId: selectedRow?.id,
      note: textNote || selectedRow?.note,
    };
    mutationUpdateNote.mutate(params);
  };

  const buildTab = () => {
    return (
      <>
        <Box
          sx={{}}
          style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Tabs value={value} onChange={handleChange} textColor="primary">
            <Tab label="Tất cả" className="left-border" />
          </Tabs>
        </Box>
      </>
    );
  };
  return (
    <div className={'page-layout'}>
      <Grid container className={'align-items-center header_title'}>
        <Grid item md={7}>
          <h2 className={'txt-title'}>QUẢN LÝ ĐƠN HÀNG</h2>
        </Grid>
        <Grid item md={5}>
          <Box style={{ display: 'flex', justifyContent: 'flex-end' }} flexDirection={{ xs: 'column', md: 'row' }}>
            <Button className={'btn-create'} onClick={exportExcel}>
              <span className={'txt'}>Xuất đơn excel</span>
            </Button>
            <Button className={'btn-create'} onClick={() => navigate('/create-order')}>
              <span className={'txt'}>Tạo đơn hàng</span>
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider />
      <Grid container className="search">
        <FormProvider {...methods}>
          <Grid item md={12} lg={10}>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={2} md={1.75} lg={1.75}>
                <FormControlCustom label="Thời gian" fullWidth>
                  <div className="view-input">
                    <SelectCustom options={filterDateTime} placeholder={'Tất cả'} name={'time'} />
                  </div>
                </FormControlCustom>
              </Grid>
              <Grid item xs={6} sm={4} md={1.75} lg={1.75}>
                <FormControlCustom label="Từ ngày" fullWidth>
                  <div className="view-input">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disabled={disable}
                        value={selectedDate?.dateFrom}
                        onChange={(e: any) => {
                          setSelectedDate({ ...selectedDate, dateFrom: new Date(e) });
                        }}
                        inputFormat="DD/MM/YYYY"
                        className={'date-picker'}
                        renderInput={(params: any) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                </FormControlCustom>
              </Grid>
              <Grid item xs={6} sm={4} md={1.75} lg={1.75}>
                <FormControlCustom label="Đến ngày" fullWidth>
                  <div className="view-input">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disabled={disable}
                        inputFormat={'DD/MM/YYYY'}
                        value={selectedDate?.dateTo}
                        onChange={(e: any) => {
                          setSelectedDate({ ...selectedDate, dateTo: new Date(e - 1000) });
                        }}
                        className={'date-picker'}
                        renderInput={(params: any) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                </FormControlCustom>
              </Grid>
              <Grid item xs={6} sm={5} md={2} lg={2}>
                <FormControlCustom label="Nhân viên phụ trách" fullWidth>
                  <div className="view-input">
                    <SelectCustom options={dataUser?.data} placeholder={'Tất cả'} name={'user'} />
                  </div>
                </FormControlCustom>
              </Grid>
              <Grid item xs={6} sm={4} md={1.75} lg={1.75}>
                <FormControlCustom label="Trạng thái" fullWidth>
                  <div className="view-input">
                    <SelectCustom options={orderStatus} placeholder={'Tất cả'} name={'status'} />
                  </div>
                </FormControlCustom>
              </Grid>
              <Grid item xs={6} sm={2} md={1.75} lg={1.75}>
                <FormControlCustom label="Phân nhóm" fullWidth>
                  <div className="view-input">
                    <SelectCustom options={orderGroup} placeholder={'Tất cả'} name={'groupId'} />
                  </div>
                </FormControlCustom>
              </Grid>
            </Grid>
          </Grid>

          <Grid md={12} lg={2} item>
            <span className="font-size-0">Tìm kiếm</span>
            <SearchInput
              className="txt-search w-30rem"
              placeholder={'Nhập thông tin tìm kiếm'}
              value={searchValue}
              setSearchValue={setSearchValue}
              handleSearch={handleSearch}
            />
          </Grid>
        </FormProvider>
      </Grid>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '64px' }}>
        <span
          style={{
            fontSize: '0.875rem',
            marginRight: '8px',
          }}
        >
          Tổng doanh thu:{' '}
        </span>
        <span
          style={{
            color: '#f8af3f',
            fontSize: '0.875rem',
            fontWeight: 600,
          }}
        >
          {currencyFormat(dataOrder?.data?.meta?.totalPrice || 0, '')}
        </span>
      </div>
      <div className="container-table">
        <OrderExpand
          rows={dataOrder?.data?.data || []}
          selectedRow={handleSelectRow}
          selectedItemChild={selectedIdChildRow}
          total={dataOrder?.data?.total}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          page={page || 0}
          pageSize={pageSize || 0}
          onShowDetail={onDetailOrder}
        />
      </div>
      <div>
        <CreateStock setShowDrawer={setShowDrawer} showDrawer={showDrawer} />
      </div>
      <SnackBarCustom open={open} onClose={() => setOpen(false)} message={'Thêm thành công'} type={'success'} />
      <ModalAlert
        open={openModal}
        handleClose={() => handleCloseModal()}
        handleCancel={() => handleCloseModal()}
        handleConfirm={onConfirmDiscount}
        title={'Giảm giá'}
        renderContentModal={
          <div className="view-input-discount">
            <TextField
              type={'number'}
              placeholder={'0'}
              style={{
                display: 'flex',
              }}
              className="input-discount"
              onChange={handleChangeDiscount}
            />
            <span style={{ position: 'absolute', right: '25px' }}>%</span>
          </div>
        }
        type={'information'}
      />
    </div>
  );
};

export default OderList;
