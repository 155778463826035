import { yupResolver } from '@hookform/resolvers/yup';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Drawer, Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useMutation, useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import SKUCreateAndEdit from 'src/admin/pages/sku/components/skuCreateAndEdit';
import clientRequest from 'src/config/axios';
import { getSupplier } from 'src/services/app.services';
import { getSearchSku } from 'src/services/stock.services';
import { uploadImage } from 'src/services/upload';
import AutocompleteCustom from 'src/shared-components/AutocompleteCustom';
import FormControlCustom from 'src/shared-components/FormControlCustom';
import InputField from 'src/shared-components/InputField';
import ModalAlert from 'src/shared-components/Modal';
import SelectCustom from 'src/shared-components/SelectCustom';
import { useToast } from 'src/shared-components/Toast';
import UploadImage from 'src/shared-components/UploadImage';
import useDebounce from 'src/shared-components/debounce';
import { branchCreate } from 'src/utils/app-data';
import { convertCurrencyNo, currencyMark, numberFormat } from 'src/utils/utils';
import '../../../layout/defaultLayout.scss';

interface ICreateStockProps {
  setShowDrawer: (data: any | undefined) => void;
  showDrawer: boolean | undefined;
  refetch: any;
  dataProduct?: any;
  type?: string;
}

const CreateStock = (props: ICreateStockProps) => {
  const { setShowDrawer, showDrawer, refetch, type, dataProduct } = props;

  const toast = useToast();
  const [images, setImages] = useState<any>();
  const [skuList, setSkuList] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<any>();
  const [filterParams, setFilterParams] = useState<any>(null);
  const [openCreateSku, setOpenCreateSku] = useState<boolean>(false);
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [imageSelected, setSelectedImage] = useState<any>();

  const debouncedSearch = useDebounce(searchValue, 500);
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  const schema = yup.object().shape({
    amount: yup.string().typeError('Vui lòng nhập số lượng').required('Vui lòng nhập số lượng'),
    entryPrice:
      user?.roles?.[0] === 1 ? yup.string().typeError('Vui lòng nhập giá').required('Vui lòng nhập giá') : yup.string(),
    price: yup.string().typeError('Vui lòng nhập giá bán').required('Vui lòng nhập giá bán'),
    branch: yup.object().typeError('Vui lòng chọn chi nhánh').required('Vui lòng chọn chi nhánh'),
    codeSku: yup.object().typeError('Vui lòng chọn sku').required('Vui lòng chọn sku'),
    supplier: yup
      .string()
      .test('supplier', 'Vui lòng nhập nhà cung cấp', function (value: any, testContext: any) {
        if (!isEmpty(value)) {
          return true;
        } else if (testContext?.parent?.branch?.codeName === 'SPUPLIER') {
          return false;
        }
        return true;
      })
      .nullable(),
  });
  const { data: searchSku, refetch: refetchSku } = useQuery(['searchSku', { ...filterParams }], (params: any) =>
    getSearchSku({ ...filterParams }),
  );
  const defaultValues: any = useMemo(() => {
    // tạm sửa để load đúng: cần phải sửa lại load đúng list varations ở DB rồi hiển thị map với dữ liệu đã lưu thì mới được
    let valueColor = '';
    let valueSize = '';
    dataProduct?.productVariations?.map((item: { code: string; value: any }) => {
      if (item.code == 'SIZE') {
        valueSize = item.value;
      }
      if (item.code == 'COLOR') {
        valueColor = item.value;
      }
    });
    let letPriceCTVTmp: any = 0;
    let letPriceSI_1Tmp: any = 0;
    let letPriceSI_2Tmp: any = 0;
    letPriceCTVTmp = dataProduct?.skuProduct?.productWholesales?.find((item: any) => item.productSaleCode === 'CTV');
    letPriceSI_1Tmp = dataProduct?.skuProduct?.productWholesales?.find((item: any) => item.productSaleCode === 'SI_1');
    letPriceSI_2Tmp = dataProduct?.skuProduct?.productWholesales?.find((item: any) => item.productSaleCode === 'SI_2');

    return {
      codeSku: dataProduct?.sku ? searchSku?.data?.find((item: any) => item?.id === dataProduct?.SKUProductId) : '',
      skuSub: dataProduct?.skuSub || '',
      fromOrder: dataProduct?.fromOrder || '',
      productName: dataProduct?.name || '',
      amount: dataProduct?.quantity || '',
      entryPrice: dataProduct?.entryPrice || '',
      price: dataProduct?.price || '',
      priceReal: dataProduct?.priceReal || '',
      supplier: dataProduct?.supplier || '',
      branch: dataProduct?.branch ? branchCreate?.find((item: any) => item?.codeName === dataProduct?.branch) : '',
      note: dataProduct?.note || '',
      valueColor: valueColor,
      valueSize: valueSize,
      images: '' || null,
      priceSI_1: `SL: ${letPriceSI_1Tmp?.quantity ?? 0} - ${convertCurrencyNo(
        dataProduct?.priceReal - (letPriceSI_1Tmp?.price ?? 0),
      )}`,
      priceCTV: `SL: ${letPriceCTVTmp?.quantity ?? 0} - ${convertCurrencyNo(
        dataProduct?.priceReal - (letPriceCTVTmp?.price ?? 0),
      )}`,
      priceSI_2: `SL: ${letPriceSI_2Tmp?.quantity ?? 0} - ${convertCurrencyNo(
        dataProduct?.priceReal - (letPriceSI_2Tmp?.price ?? 0),
      )}`,
    };
  }, [JSON.stringify(dataProduct)]);

  const methods = useForm({
    mode: 'onSubmit',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset, formState, setValue, watch } = methods;

  const { errors } = formState;

  const skuWatch = watch('codeSku');
  const branchWatch = watch('branch');
  const variationData = watch('variation');
  const watchPriceEnter = watch('entryPrice');
  const watchPrice = watch('price');
  const watchPriceReal = watch('priceReal');
  const [dataVar, setDataVar] = useState<any>([
    {
      value: '',
      name: 'Color',
    },
    {
      value: '',
      name: 'Size',
    },
  ]);

  const { data: dataSupplier } = useQuery(['dataSupplier'], () => getSupplier({}));
  const mutationStock = useMutation({
    mutationFn: (params: any) => {
      if (type === 'update') {
        return clientRequest.put(`product/${dataProduct?.id}`, {
          ...params,
        });
      } else {
        return clientRequest.post(`product`, {
          ...params,
        });
      }
    },
    onSuccess: () => {
      toast('success', type === 'update' ? 'Cập nhật thành công' : 'Thêm mới thành công');
      refetch();
      reset({ ...defaultValues });
      setShowDrawer(false);
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', 'Không thành công');
      }
    },
    onSettled: () => {
      refetch();
    },
  });

  useEffect(() => {
    setValue('entryPrice', currencyMark(watchPriceEnter));
    setValue('price', currencyMark(watchPrice));
    setValue('priceReal', currencyMark(watchPriceReal));
  }, [watchPriceEnter, watchPrice, watchPriceReal]);

  useEffect(() => {
    reset({ ...defaultValues });
    setImages(dataProduct?.images || []);
  }, [dataProduct, searchSku]);

  // useEffect(() => {
  //   setValue(
  //     'priceSI_1',
  //     `SL: ${watchPriceSI_1?.quantity ?? 0} - ${convertCurrencyNo(
  //       dataProduct?.priceReal - (watchPriceSI_1?.price ?? 0),
  //     )}`,
  //   );
  // }, [watchPriceSI_1]);
  useEffect(() => {
    if (!isEmpty(searchSku?.data)) {
      let sku = [...searchSku?.data];
      sku?.unshift({ id: 0, sku: '', phone: '' });
      setSkuList(sku);
    } else {
      let sku = [];
      sku?.unshift({ id: 0, sku: '', phone: '' });
      setSkuList(sku);
    }
  }, [dataProduct, searchSku]);

  useEffect(() => {
    setFilterParams({ ...filterParams, key: debouncedSearch?.data });
  }, [debouncedSearch?.data]);

  useEffect(() => {
    if (type !== 'update') {
      const codeSku: any = skuWatch;
      setValue('productName', codeSku?.name);
      // checkBranch();
    }
    // if (checkBranch()) {
    //   setValue('supplier', '');
    // }
  }, [skuWatch, branchWatch]);

  useEffect(() => {
    if (type !== 'update') {
      const codeSku: any = skuWatch;
      setImages(codeSku?.images);
    }
  }, [skuWatch]);

  const goBack = () => {
    reset();
    setShowDrawer(false);
    setValue('variation', []);
    setDataVar([]);
    setImages([]);
  };

  useEffect(() => {
    if (isEmpty(dataVar)) {
      let tmpForm = [
        ...watch('variation'),
        {
          value: '',
          name: 'Color',
        },
        {
          value: '',
          name: 'Size',
        },
      ];
      setDataVar(JSON.parse(JSON.stringify(tmpForm)));
      setValue('variation', JSON.parse(JSON.stringify(tmpForm)));
    }
  }, [variationData]);

  useEffect(() => {
    setFilterParams({ ...filterParams, key: debouncedSearch?.data });
  }, [debouncedSearch?.data]);

  const checkBranch = () => {
    if (branchWatch?.codeName == 'SPUPLIER') {
      return false;
    } else {
      return true;
    }
  };

  const getUrlFromIMG = async (fromData: any) => {
    setLoadingUpload(true);
    let data = new FormData();
    data.append('images', fromData.file, fromData.file.name);
    const a = await uploadImage(data);
    setLoadingUpload(false);
    return a?.data?.images[0];
  };
  const onChange = async (imageList: any, addUpdateIndex: any) => {
    if (addUpdateIndex) {
      imageList[addUpdateIndex] = await getUrlFromIMG(imageList[addUpdateIndex]);
    }
    setImages(imageList);
  };

  const onChangeCustomer = (data: any) => {
    setSearchValue({
      data,
    });
  };

  const toggleDrawer = (open: any) => (event: any) => {
    setShowDrawer(open);
    reset();
    setValue('variation', []);
    setDataVar([]);
  };

  const getTitle = (type: any) => {
    if (type === 'update') {
      return 'Cập nhật';
    } else {
      return 'Thêm mới';
    }
  };

  const onSelectedImage = (image: any) => {
    setSelectedImage(image);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const onSubmit = (value: any) => {
    const params = {
      skuProductId: value?.codeSku.id || value?.dataProduct?.id,
      skuCode: value?.codeSku?.sku,
      user: user.userName,
      images: value?.images && value?.images,
      branch: value.branch.codeName,
      name: value.productName,
      quantity: numberFormat(value?.amount),
      entryPrice: numberFormat(value?.entryPrice),
      price: numberFormat(value?.price),
      note: value.note,
      weight: 0,
      supplier: value?.supplier,
      variations: [
        {
          name: 'Color',
          value: value.valueColor,
        },
        {
          name: 'Size',
          value: value.valueSize,
        },
      ],
    };
    mutationStock.mutate(params);
  };
  const a = useCallback(
    (option: any, props: any, state: any) => {
      return (
        <>
          {option.id === 0 && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button className="btn-tertiary-disable" style={{ width: '100%' }} onClick={() => setOpenCreateSku(true)}>
                <AddOutlinedIcon style={{ fontSize: '16px' }} />
                <span>Thêm SKU </span>
              </Button>
            </div>
          )}
          {option.id === 0 ? (
            <></>
          ) : (
            <div style={option.id === 0 ? {} : { paddingTop: '3px', paddingBottom: '3px' }}>
              <div style={{ padding: '3px', display: 'flex', justifyContent: 'center' }} {...props}>
                <div style={{ width: '200px', display: 'flex', justifyContent: 'center' }}>
                  <span> {option.sku}</span>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <span>{option.phone}</span>
                </div>
              </div>
            </div>
          )}
        </>
      );
    },
    [undefined],
  );

  return (
    <Drawer
      PaperProps={{
        sx: { width: '50%' },
      }}
      anchor={'right'}
      open={showDrawer}
      className={'drawer'}
      onClose={toggleDrawer(false)}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="title-drawer">
            <div className="btn-close" onClick={goBack}>
              <ArrowBackIosIcon className="icon-back" />
            </div>
            <div>
              <span>{getTitle(type)}</span>
            </div>
          </div>
          <div className="content-drawer">
            <div className="title-group">
              <span>Nhập stock</span>
            </div>
            <div className="content">
              <Grid container spacing={1.5}>
                <Grid item xs={4}>
                  <FormControlCustom label={'Mã SKU'} fullWidth>
                    {/* <SelectCustom
                      name={'codeSku'}
                      placeholder={'Chọn mã Sku'}
                      error={Boolean(errors?.codeSku)}
                      helperText={errors?.codeSku?.message as string}
                      options={searchSku?.data || []}
                      disabled={type === 'update' ? true : false}
                    /> */}
                    <AutocompleteCustom
                      style={{ width: '100%' }}
                      name={'codeSku'}
                      placeholder={'Chọn mã Sku'}
                      error={Boolean(errors?.codeSku)}
                      options={skuList || []}
                      renderOption={a}
                      onChangeValue={onChangeCustomer}
                      optionLabelKey={'sku'}
                      disabled={type === 'update' ? true : false}
                      className={type === 'update' ? 'disabled-field' : ''}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={!isEmpty(user) && user?.roles?.includes(1) ? 4 : 8}>
                  <FormControlCustom label={'Tên sản phẩm'} fullWidth>
                    <InputField
                      name={'productName'}
                      placeholder={'Nhập tên sản phẩm'}
                      error={Boolean(errors.productName)}
                      helperText={errors?.productName?.message as string}
                      disabled={true}
                      className={'disabled-field'}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={4}>
                  <FormControlCustom label={'Số lượng'} fullWidth>
                    <InputField
                      name={'amount'}
                      helperText={errors?.amount?.message as string}
                      error={Boolean(errors.amount)}
                      placeholder={'0'}
                      onKeyDown={(evt: any) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                      type="number"
                      InputProps={
                        type === 'update'
                          ? { inputMode: 'numeric', pattern: '[0-9]*', min: 0 }
                          : { inputMode: 'numeric', pattern: '[0-9]*', min: 1 }
                      }
                      disabled={isEmpty(user) || (!user?.roles?.includes(1) && type === 'update')}
                      className={
                        isEmpty(user) || (!user?.roles?.includes(1) && type === 'update') ? 'disabled-field' : ''
                      }
                    />
                  </FormControlCustom>
                </Grid>
                {!isEmpty(user) && user?.roles?.includes(1) ? (
                  <>
                    <Grid item xs={4}>
                      <FormControlCustom label={'Giá nhập'} fullWidth>
                        <InputField
                          name={'entryPrice'}
                          helperText={errors?.entryPrice?.message as string}
                          error={Boolean(errors.entryPrice)}
                          placeholder={'0'}
                          disabled={user?.roles?.includes(9)}
                          className={user?.roles?.includes(9) ? 'disabled-field' : ''}
                        />
                      </FormControlCustom>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}

                <Grid item xs={4}>
                  <FormControlCustom label={'Giá bán gốc'} fullWidth>
                    <InputField
                      name={'price'}
                      helperText={errors?.price?.message as string}
                      error={Boolean(errors.price)}
                      placeholder={'0'}
                      disabled={isEmpty(user) || (!user?.roles?.includes(1) && type === 'update')}
                      className={
                        isEmpty(user) || (!user?.roles?.includes(1) && type === 'update') ? 'disabled-field' : ''
                      }
                    />
                  </FormControlCustom>
                </Grid>
                {!isEmpty(user) && user?.roles?.includes(1) ? (
                  <>
                    <Grid item xs={4}>
                      <FormControlCustom label={'Giá bán cuối'} fullWidth>
                        <InputField
                          name={'priceReal'}
                          helperText={errors?.priceReal?.message as string}
                          error={Boolean(errors.priceReal)}
                          placeholder={'0'}
                          disabled={true}
                          className={'disabled-field'}
                          // user?.roles?.includes(9)
                          // user?.roles?.includes(9) ?
                        />
                      </FormControlCustom>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
                {dataProduct?.id && (
                  <>
                    <Grid item xs={4}>
                      <FormControlCustom label={'CTV'} fullWidth>
                        <InputField name={'priceCTV'} disabled={true} className={'disabled-field'} />
                      </FormControlCustom>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControlCustom label={'Sỉ 1'} fullWidth>
                        <InputField name={'priceSI_1'} disabled={true} className={'disabled-field'} />
                      </FormControlCustom>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControlCustom label={'Sỉ 2'} fullWidth>
                        <InputField name={'priceSI_2'} disabled={true} className={'disabled-field'} />
                      </FormControlCustom>
                    </Grid>
                  </>
                )}
                <Grid item xs={4}>
                  <FormControlCustom label={'Chi nhánh'} fullWidth>
                    <SelectCustom
                      name={'branch'}
                      placeholder={'Chọn chi nhánh'}
                      error={Boolean(errors.branch)}
                      helperText={errors?.branch?.message as string}
                      options={branchCreate || []}
                    />
                  </FormControlCustom>
                </Grid>
                {!dataProduct?.id ? (
                  <Grid item xs={8}>
                    <FormControlCustom label={'Nhà cung cấp'} fullWidth>
                      <SelectCustom
                        name={'supplier'}
                        placeholder={''}
                        options={dataSupplier?.data || []}
                        freeSolo={true}
                        isAutoSelected={true}
                        // disabled={checkBranch()}
                        helperText={errors?.supplier?.message as string}
                        error={Boolean(errors.supplier)}
                      />
                    </FormControlCustom>
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={4}>
                      <FormControlCustom label={'Nhà cung cấp'} listOldOrderId={JSON.parse(dataProduct?.fromOrderList ?? '[]')} oldOrderId={dataProduct?.fromOrder} fullWidth>
                        <SelectCustom
                          name={'supplier'}
                          placeholder={''}
                          options={dataSupplier?.data || []}
                          freeSolo={true}
                          isAutoSelected={true}
                          // disabled={checkBranch()}
                          helperText={errors?.supplier?.message as string}
                          error={Boolean(errors.supplier)}
                        />
                      </FormControlCustom>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControlCustom label={'Mã sản phẩm'} fullWidth>
                        <InputField
                          name={'skuSub'}
                          className={'disabled-field-high'}
                          InputProps={{ readOnly: true }}
                          onClick={() => {
                            window.open(
                              `https://shipus.vn/san-pham/${dataProduct?.SKUProductId}?productId=${dataProduct?.id}`,
                              '_blank',
                            );
                          }}
                          style={{
                            textDecorationLine: 'underline',
                            color: '#1A89AC',
                          }}
                        />
                      </FormControlCustom>
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <FormControlCustom label={'Ghi chú'} fullWidth>
                    <InputField name={'note'} placeholder={'Nhập ghi chú'} />
                  </FormControlCustom>
                </Grid>
              </Grid>
            </div>
            <div className="title-group">
              <span>Hình ảnh sản phẩm</span>
            </div>
            <div className="view-image">
              <div className={isEmpty(images) ? 'image-product' : ''}>
                <UploadImage
                  onChange={onChange}
                  images={!isEmpty(images) ? images : []}
                  isLoading={loadingUpload}
                  sortAble
                  setItems={(images: any) => setValue('images', images)}
                  openImage={onSelectedImage}
                />
              </div>
            </div>

            <div className="title-group">
              <span>Đặc tính sản phẩm</span>
            </div>
            <div className="content">
              <TableContainer component={Paper} className={'drawer-table'}>
                <Table
                  sx={{
                    minWidth: 450,
                    '&:last-child td, &:last-child th': {
                      border: '1px solid #D7D7D7',
                    },
                    boxShadow: 'unset',
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow sx={{ backgroundColor: 'rgba(245, 245, 245, 0.5)' }}>
                      <TableCell width={'150'} align="center">
                        Tên
                      </TableCell>
                      <TableCell width={'200'} align="center">
                        Giá trị
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          Width: 200,
                          wordBreak: 'break-word',
                        }}
                      >
                        <InputField name={`a`} defaultValue={'Color'} disabled className={'disabled-field'} />
                      </TableCell>
                      <TableCell align="left" sx={{ maxWidth: 200, wordBreak: 'break-word' }}>
                        <InputField name={`valueColor`} placeholder={'Nhập giá trị'} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          Width: 200,
                          wordBreak: 'break-word',
                        }}
                      >
                        <InputField name={`a`} defaultValue={'Size'} disabled className={'disabled-field'} />
                      </TableCell>
                      <TableCell align="left" sx={{ maxWidth: 200, wordBreak: 'break-word' }}>
                        <InputField name={`valueSize`} placeholder={'Nhập giá trị'} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <Button className={'btn-add'}>
                <div className={'view-btn-add'} onClick={onAddForm}>
                  <AddOutlinedIcon />
                  <span> Thêm thuộc tính</span>
                </div>
              </Button> */}
            </div>
          </div>

          <div className="footer-drawer">
            <Grid container spacing={3} justifyContent="space-between" alignItems="center">
              <Grid item xs={6} display="flex" justifyContent="end">
                <Button className="btn-secondary-disable" onClick={goBack}>
                  Quay lại
                </Button>
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="end">
                <Button className={'btn-tertiary-normal'} type="submit">
                  {'Xác nhận'}
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>
      </FormProvider>
      <SKUCreateAndEdit setShowDrawer={setOpenCreateSku} showDrawer={openCreateSku} refetch={refetchSku} />
      <ModalAlert
        open={openModal}
        handleClose={() => handleCloseModal()}
        renderContentModal={<img src={imageSelected} width={500} />}
        isButton={true}
        image={false}
      />
    </Drawer>
  );
};

export default CreateStock;
